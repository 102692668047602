<template>
	<div>
		<el-row style="padding:20px 0 10px">
			<el-col :span="6"
				>提现笔数：{{ allCount.totalOrderCount || 0 }}笔</el-col
			>
			<el-col :span="6"
				>提现金额：{{ allCount.totalExtractionAmount || 0 }}元</el-col
			>
			<el-col :span="6"
				>打款金额：{{ allCount.totalTransferAmount || 0 }}元</el-col
			>
		</el-row>
		<el-table
		    stripe
			v-loading="loading"
			:data="dataList"
			@selection-change="handleSelectionChange"
		>
			<!-- 复选框-->
			<el-table-column type="selection" width="55" disabled>
				<!-- <template v-slot="{ row }">
					<el-checkbox
						v-model="checked"
						:disabled="
							row.extractionStatus == 'WAITING' ? false : true
						"
					></el-checkbox>
				</template> -->
			</el-table-column>
			<el-table-column
				label="提现订单"
				prop="orderNo"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column label="出款通道" prop="paymentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.paymentName}}<span v-if="scope.row.paymentNo">({{scope.row.paymentNo}})</span>
                </template>
            </el-table-column>
			<el-table-column
				label="代理商/机构编号"
				prop="userNo"
				min-width="140"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="代理商/机构名称"
				prop="userName"
				min-width="140"
				:show-overflow-tooltip="true"
			/>
			<el-table-column label="一级代理商" prop="oneAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.oneAgentNo}} - {{scope.row.oneAgentName}}
                </template>
            </el-table-column>
			<el-table-column
				label="上游通道"
				prop="channelNo"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.channelNo | channelFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="账户类型"
				prop="accountType"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.accountType | accountTypeFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="提现时间"
				prop="extractionTime"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="提现状态"
				prop="extractionStatus"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
                    <el-button :type="scope.row.extractionStatus=='SUCCESS'?'success':scope.row.extractionStatus=='FAILED'?'danger':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.extractionStatus">{{scope.row.extractionStatus | extractionStatusFM}}</el-button>
                </template>
			</el-table-column>
			<el-table-column
				label="提现金额(元)"
				prop="extractionAmount"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<!-- <el-table-column label="止付金额(元)" prop="suspendAmount" min-width="120" :show-overflow-tooltip="true"/> -->
			<el-table-column
				label="手续费(元)"
				prop="extractionFee"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="打款金额"
				prop="actualAmount"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="打款卡号"
				prop="accountNo"
				min-width="160"
				:show-overflow-tooltip="true"
			/>
			<!-- <el-table-column label="打款状态" prop="transferStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.transferStatus | transferStatusFM}}
                </template>
            </el-table-column> -->
			<el-table-column
				label="打款流水"
				prop="lsApplyId"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="打款时间"
				prop="transferTime"
				min-width="160"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="审核时间"
				prop="auditTime"
				min-width="160"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="审核人"
				prop="auditUserName"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="审核备注"
				prop="auditRemark"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="上游返回备注"
				prop="auditFailedRemark"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作"
				align="center"
				class-name="small-padding fixed-width"
				width="80"
				fixed="right"
			>
				<template slot-scope="scope">
					<el-button
						size="mini"
						type="text"
						v-if="
							scope.row.auditStatus == 'WAITING' &&
								scope.row.extractionStatus == 'WAITING'
						"
						@click="handleAudit(scope.row)"
						v-permission="'EXTRACTION_ORDER_AUDIT'">
						<i class="el-icon-edit-outline"></i>
						审核
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
		<CustomForm
			ref="CustomForm"
			:dicts="dicts"
			:on-fresh="showCustomForm"
			@on-close="handlerCustomFormOnclose"
			:selectRow="selectRow"
		>
		</CustomForm>
		<CustomForm2
			ref="CustomForm2"
			:dicts="dicts"
			:on-fresh="showCustomForm2"
			@on-close="handlerCustomFormOnclose2"
			:selectRow="selectRow"
		>
		</CustomForm2>
	</div>
</template>

<script>
import CustomForm from "./CustomForm";
import CustomForm2 from "./CustomForm2";
import { WithdrawApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	components: { CustomForm, CustomForm2 },
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [], //列表数据
			selectRow: {},
			showCustomForm: false,
			showCustomForm2: false,
			isAdd: false,
			allCount: "",
			checked: false
		};
	},
	mounted() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		// 获取列表
		async getList() {
			this.loading = true;
			// 分页查询提现订单
			let result = await WithdrawApi.queryPage(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			console.log("queryPage----->101", result.data.orderPageList);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data.orderPageList || [];
			this.allCount = result.data.orderCountInfo || [];
		},
		// 关闭弹窗，获取列表更新数据
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		handlerCustomFormOnclose2() {
			this.showCustomForm2 = false;
			this.getList();
		},
		// 审核按钮事件
		async handleAudit(row) {
			this.showCustomForm2 = true;
			this.selectRow = row;
			// console.log("handleAudit----->266", row.orderNo);
			// this.$refs.CustomForm2.submit(row.orderNo);
		},
		// 复选框ID事件
		handleSelectionChange(selection) {
			// console.log("selection----->", selection);
			// 拼接赋值ID给receivedID
			let ids = selection.map(item => item.id);
			// 触发mutation的复选框ID
			this.$store.commit("app/addReceivedID", ids);
			console.log("ids----->281", ids);
			// this.$refs.CustomForm.handleSelectiFn(ids);
		}
	}
};
</script>

<style scoped></style>
